@media (prefers-color-scheme: dark) {
  .loading{
    background-color: rgba(38,38,38);
  }
}

.loading {
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;

  &>section {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: teal;
  }
}

.loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3em;
  }
  
  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
      